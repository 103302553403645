import { ValidationTypes } from "constants/WidgetValidation";
import { EvaluationSubstitutionType } from "entities/DataTree/dataTreeFactory";
import type { ChartWidgetProps } from "widgets/ChartWidget/widget";
import {
  CUSTOM_CHART_TYPES,
  LabelOrientation,
  LABEL_ORIENTATION_COMPATIBLE_CHARTS,
} from "../constants";
import type { WidgetProps } from "widgets/BaseWidget";

export const isLabelOrientationApplicableFor = (chartType: string) =>
  LABEL_ORIENTATION_COMPATIBLE_CHARTS.includes(chartType);

const labelOptions = () => {
  const options = [
    {
      label: "折线图",
      value: "LINE_CHART",
    },
    {
      label: "条形图",
      value: "BAR_CHART",
    },
    {
      label: "饼图",
      value: "PIE_CHART",
    },
    {
      label: "柱状图",
      value: "COLUMN_CHART",
    },
    {
      label: "面积图",
      value: "AREA_CHART",
    },
    {
      label: "自定义 EChart",
      value: "CUSTOM_ECHART",
    },
    {
      label: "自定义 Fusion 图表（已废弃）",
      value: "CUSTOM_FUSION_CHART",
    },
  ];

  return options;
};

export const contentConfig = () => {
  return [
    {
      sectionName: "数据",
      children: [
        {
          helpText: "修改数据展示方式",
          propertyName: "chartType",
          label: "图表类型",
          controlType: "DROP_DOWN",
          options: labelOptions(),
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.TEXT,
            params: {
              allowedValues: [
                "LINE_CHART",
                "BAR_CHART",
                "PIE_CHART",
                "COLUMN_CHART",
                "AREA_CHART",
                "CUSTOM_ECHART",
                "CUSTOM_FUSION_CHART",
              ],
            },
          },
        },
        {
          helpText: "前往 docs.appsmith.com 查看如何配置",
          placeholderText: `自定义 ECharts 配置`,
          propertyName: "customEChartConfig",
          label: "自定义 ECharts 配置",
          controlType: "WRAPPED_CODE_EDITOR",
          controlConfig: {
            wrapperCode: {
              prefix: "{{ ((chartType) => ( ",
              suffix: (widget: WidgetProps) =>
                `))(${widget.widgetName}.chartType) }}`,
            },
          },
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.OBJECT_WITH_FUNCTION,
            params: {
              default: {},
            },
          },
          hidden: (props: ChartWidgetProps) =>
            props.chartType !== "CUSTOM_ECHART",
          dependencies: ["chartType"],
        },
        {
          helpText: "前往 docs.appsmith.com 查看如何配置",
          placeholderText: `自定义 Fusion 配置`,
          propertyName: "customFusionChartConfig",
          label: "自定义 Fusion 配置",
          controlType: "INPUT_TEXT",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: {
            type: ValidationTypes.OBJECT,
            params: {
              default: {},
              allowedKeys: [
                {
                  type: ValidationTypes.TEXT,
                  name: "type",
                  params: {
                    allowedValues: CUSTOM_CHART_TYPES,
                    default: "",
                    required: true,
                  },
                },
                {
                  type: ValidationTypes.OBJECT,
                  name: "dataSource",
                  params: {
                    required: true,
                    ignoreCase: false,
                    allowedKeys: [
                      {
                        name: "chart",
                        type: ValidationTypes.OBJECT,
                        params: {
                          allowedKeys: [
                            {
                              name: "paletteColors",
                              type: ValidationTypes.TEXT,
                              params: {
                                strict: true,
                                ignoreCase: true,
                              },
                            },
                          ],
                          default: {},
                        },
                      },
                      {
                        name: "data",
                        type: ValidationTypes.ARRAY,
                        params: {
                          default: [],
                          children: {
                            type: ValidationTypes.OBJECT,
                            params: {
                              allowedKeys: [
                                {
                                  name: "label",
                                  type: ValidationTypes.TEXT,
                                },
                                {
                                  name: "value",
                                  type: ValidationTypes.NUMBER,
                                },
                              ],
                            },
                          },
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          hidden: (props: ChartWidgetProps) =>
            props.chartType !== "CUSTOM_FUSION_CHART",
          dependencies: ["chartType"],
          evaluationSubstitutionType:
            EvaluationSubstitutionType.SMART_SUBSTITUTE,
        },
        {
          helpText: "填充图表的一组数据",
          propertyName: "chartData",
          placeholderText: '[{ "x": "2021", "y": "94000" }]',
          label: "图表数据系列",
          controlType: "CHART_DATA",
          isBindProperty: false,
          isTriggerProperty: false,
          hidden: (props: ChartWidgetProps) =>
            ["CUSTOM_FUSION_CHART", "CUSTOM_ECHART"].includes(props.chartType),
          dependencies: ["chartType"],
          children: [
            {
              helpText: "系列数据",
              propertyName: "data",
              label: "系列数据",
              controlType: "INPUT_TEXT_AREA",
              isBindProperty: true,
              isTriggerProperty: false,
              validation: {
                type: ValidationTypes.ARRAY,
                params: {
                  default: [],
                  children: {
                    type: ValidationTypes.OBJECT,
                    params: {
                      required: true,
                      allowedKeys: [
                        {
                          name: "x",
                          type: ValidationTypes.TEXT,
                          params: {
                            required: true,
                            default: "",
                          },
                        },
                        {
                          name: "y",
                          type: ValidationTypes.NUMBER,
                          params: {
                            required: true,
                            default: 10,
                          },
                        },
                      ],
                    },
                  },
                },
              },
              evaluationSubstitutionType:
                EvaluationSubstitutionType.SMART_SUBSTITUTE,
            },
            {
              helpText: "系列名称",
              propertyName: "seriesName",
              label: "系列名称",
              controlType: "INPUT_TEXT",
              isBindProperty: true,
              isTriggerProperty: false,
              validation: { type: ValidationTypes.TEXT },
            },
          ],
        },
      ],
    },
    {
      sectionName: "通用配置",
      children: [
        {
          helpText: "图表标题",
          placeholderText: "销售报告",
          propertyName: "chartName",
          label: "标题",
          controlType: "INPUT_TEXT",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (props: ChartWidgetProps) =>
            ["CUSTOM_ECHART"].includes(props.chartType),
        },
        {
          propertyName: "isVisible",
          label: "是否显示",
          helpText: "控制组件的显示/隐藏",
          controlType: "SWITCH",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.BOOLEAN },
        },
        {
          propertyName: "animateLoading",
          label: "加载时显示动画",
          controlType: "SWITCH",
          helpText: "组件依赖的数据加载时显示加载动画",
          defaultValue: true,
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.BOOLEAN },
        },
        {
          helpText: "允许组件内部图表滚动",
          propertyName: "allowScroll",
          label: "允许滚动",
          controlType: "SWITCH",
          isBindProperty: false,
          isTriggerProperty: false,
          hidden: (props: ChartWidgetProps) =>
            ["CUSTOM_FUSION_CHART", "PIE_CHART", "CUSTOM_ECHART"].includes(
              props.chartType,
            ),
          dependencies: ["chartType"],
        },
        {
          helpText: "显示或隐藏系列数据中数据点的标签",
          propertyName: "showDataPointLabel",
          label: "显示标签",
          controlType: "SWITCH",
          isBindProperty: false,
          isTriggerProperty: false,
          hidden: (props: ChartWidgetProps) =>
            ["CUSTOM_FUSION_CHART", "CUSTOM_ECHART"].includes(props.chartType),
          dependencies: ["chartType"],
        },
      ],
    },
    {
      sectionName: "坐标轴",
      children: [
        {
          propertyName: "setAdaptiveYMin",
          label: "自适应坐标轴",
          helpText: "自动计算最小值保证坐标轴刻度的均匀分布",
          controlType: "SWITCH",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.BOOLEAN },
          hidden: (props: any) => props.chartType == "CUSTOM_ECHART",
        },
        {
          helpText: "指定 x 轴标签",
          propertyName: "xAxisName",
          placeholderText: "日期",
          label: "y 轴标签",
          controlType: "INPUT_TEXT",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (props: any) =>
            ["CUSTOM_FUSION_CHART", "CUSTOM_ECHART"].includes(props.chartType),
          dependencies: ["chartType"],
        },
        {
          helpText: "指定 y 轴标签",
          propertyName: "yAxisName",
          placeholderText: "收入",
          label: "y 轴标签",
          controlType: "INPUT_TEXT",
          isBindProperty: true,
          isTriggerProperty: false,
          validation: { type: ValidationTypes.TEXT },
          hidden: (props: any) =>
            ["CUSTOM_FUSION_CHART", "CUSTOM_ECHART"].includes(props.chartType),
          dependencies: ["chartType"],
        },
        {
          helpText: "修改 x 轴标签方向",
          propertyName: "labelOrientation",
          label: "x 轴标签方向",
          hidden: (props: ChartWidgetProps) =>
            !isLabelOrientationApplicableFor(props.chartType),
          isBindProperty: false,
          isTriggerProperty: false,
          dependencies: ["chartType"],
          controlType: "DROP_DOWN",
          options: [
            {
              label: "自动",
              value: LabelOrientation.AUTO,
            },
            {
              label: "倾斜",
              value: LabelOrientation.SLANT,
            },
            {
              label: "旋转",
              value: LabelOrientation.ROTATE,
            },
          ],
        },
      ],
    },
    {
      sectionName: "事件",
      children: [
        {
          helpText: "点击图表数据点时",
          propertyName: "onDataPointClick",
          label: "onDataPointClick",
          controlType: "ACTION_SELECTOR",
          isJSConvertible: true,
          isBindProperty: true,
          isTriggerProperty: true,
        },
      ],
    },
  ];
};

export const styleConfig = [
  {
    sectionName: "轮廓样式",
    children: [
      {
        propertyName: "borderRadius",
        label: "边框圆角",
        helpText: "边框圆角样式",
        controlType: "BORDER_RADIUS_OPTIONS",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
      {
        propertyName: "boxShadow",
        label: "阴影",
        helpText: "组件轮廓投影",
        controlType: "BOX_SHADOW_OPTIONS",
        isJSConvertible: true,
        isBindProperty: true,
        isTriggerProperty: false,
        validation: { type: ValidationTypes.TEXT },
      },
    ],
  },
];
